import axios from 'axios'
import { PLATFORM_API_URL, PLATFORM_API_VERSION } from '../config'
import { getApiUrl } from './routing'
import _sortBy from 'lodash/sortBy'
import _find from 'lodash/find'

const getRuntimePlatform = () => 'Web'

//If the sanitize function is called on an object
//if will remove the ignored fields
const IGNORED_FIELDS_ON_SANITIZE = ['_id']

async function getPrices(products, currency, auth) {
  // console.log("WARNING: getPrices is disabled");
  // return []
  const apiUrl = getApiUrl()
  const prices = await axios.post(`${apiUrl}/payment-gateway/prices`, {
    currency,
    credentials: auth,
    ids: products.map((p) => p.real_price),
  })

  for (const product of products) {
    product.price = prices.data.prices[product.real_price]
  }

  _sortBy(products, 'price.amount')
  return products
}

//Send only meaningful parameters to the rest api backend
//@param payload Table Returns a table that has the ignore fields stripped
const sanitize = (payload) => {
  const result = {}
  const keys = Object.keys(payload)
  for (const key of keys) {
    const value = payload[key]
    //null and undefined values
    if (value === null || value === undefined) continue

    //Empty string values
    if (
      key &&
      !(typeof value == 'string' && value.length === 0) &&
      !~IGNORED_FIELDS_ON_SANITIZE.indexOf(key)
    ) {
      //if the value is an object, sanitize it
      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(typeof window !== 'undefined' && value instanceof File)
      ) {
        result[key] = sanitize(value)
      } else {
        result[key] = value
      }
    }
  }
  return result
}

//constructs an api end point
const ep = (name) => `${PLATFORM_API_URL}/${PLATFORM_API_VERSION}/${name}`

export default class PTRestAPI {
  //use this flag to break the await auth chain
  static BreakAwaitAuth = false

  static async CreateDevice() {
    const payload = {
      create: sanitize({
        platform: getRuntimePlatform(),
        model: navigator.userAgent,
      }),
    }
    const response = await axios.post(ep('user-device'), payload)

    return response.data
  }

  static async CreateUser(auth) {
    const response = await axios.post(ep('user'), {
      create: sanitize(auth),
    })
    return response.data
  }

  static async IsDeviceLogged(auth) {
    const response = await axios.post(ep('user-device'), {
      login_state: sanitize(auth),
    })
    return response?.data?.login || false
  }

  static async VerifyCredentials(auth) {
    const response = await axios.post(ep('user'), {
      verify: sanitize(auth),
    })
    return response.data
  }

  static async Login(auth) {
    const response = await axios.post(ep('user'), {
      login: sanitize(auth),
    })
    return response.data
  }

  static async RequestInternalID(auth) {
    const response = await axios.post(ep('user'), {
      internal_id: sanitize(auth),
    })
    return response.data.result
  }

  static async GetStoreProducts(auth, selector) {
    //this field requires auth
    const authCategories = ['user-products', 'user-subscriptions']

    if (
      ~selector.fields?.indexOf('is_bought') ||
      authCategories.indexOf(selector.category)
    )
      selector = { ...selector, ...auth }

    const response = await axios.post(ep('store'), {
      products: sanitize(selector),
    })
    return response.data ? response.data.products : null
  }

  static async GetUserSubscriptions(auth, selector) {
    return this.GetStoreProducts(auth, {
      ...selector,
      category: 'user-subscriptions',
    })
  }

  static async SendUserSiteContact(payload) {
    //the command
    payload = {
      ...payload,
      site_contact: true,
    }
    const formData = new FormData()
    for (const [key, value] of Object.entries(sanitize(payload))) {
      formData.append(key, value)
    }

    const response = await axios.post(ep('user-message'), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  }

  static async GetUserProfile(auth, selector) {
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, ...selector }),
    })
    return response.data
  }

  static async UserPreferences(auth, selector) {
    //if selector is a string: "field1 field2" the request is interpreted as GET
    //if selector is an object: { field1: value1, field2: value2 } the request is interpreted as POST
    const response = await axios.post(ep('user'), {
      preferences: sanitize({ ...auth, ...{ data: selector } }),
    })
    return response.data
  }

  static async ChangeUserName(auth, name) {
    name = name.trim()
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, update: { name } }),
    })

    return response.data
  }

  static async ChangeUserAvatar(auth, avatar) {
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, update: { avatar } }),
    })

    return response.data
  }

  static async ChangeUserEmail(auth, email) {
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, update: { email } }),
    })

    return response.data
  }

  static async GetStoreAccount(auth) {
    const response = await axios.post(ep('store'), {
      account: sanitize(auth),
    })
    return response.data
  }

  static async StorePurchase(auth, data) {
    const response = await axios.post(ep('store'), {
      transaction: sanitize({ ...data, ...auth }),
    })
    return response.data
  }

  static async GetSupportedLanguages() {
    const response = await axios.post(ep('localization'), {
      languages: true,
    })
    return response.data
  }

  static async GetSupportedTranslationLanguages() {
    const response = await axios.post(ep('languages'), {})
    return response.data
  }
  //TODO: This would be a server call that encodes the receipt
  //TODO: The server should also invalidate the receipt after it has been used, so it can't be used again
  // Generate a receipt for a product
  //@param pid any
  static async GenerateReceipt(pid) {
    return 'aldoo:' + pid
  }


  static async EstimateTranslationOrderCost(auth, orderID) {
    const response = await axios.post(
      ep('translate'),
      sanitize({ estimate: { orderID }, ...auth }),
    )
    return response.data
  }

  static async GetOrderStatus(auth, orderID, listFiles, exposeFiles) {
    try {
      const payload = {
        ...auth,
        status: {
          orderID: orderID,
          listFiles: listFiles,
          expose: exposeFiles,
        },
      }

      const response = await axios.post(ep('order'), sanitize(payload), {
        headers: { 'Content-Type': 'application/json' },
      })

      return response.data
    } catch (error) {
      console.error('Error in orderStatus:', error)
      throw error
    }
  }

  static async SubmitMultipleFiles(auth, orderID, files, filename) {
    const payload = {
      addFile: 1,
      orderID,
      ...auth,
    }

    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      formData.append(`document_${i}`, {
        file_path: files[i],
        name: `${filename}${i}.png`,
      })
    }

    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value)
    }

    const response = await axios.post(ep('order'), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  }

  static async SubmitFile(auth, orderID, file) {
    const payload = {
      addFile: 1,
      orderID,
      document: file,
      ...auth,
    }

    const formData = new FormData()
    for (const [key, value] of Object.entries(sanitize(payload))) {
      formData.append(key, value)
    }

    const response = await axios.post(ep('order'), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return response.data
  }

  static async SetPdfPassword(auth, orderID, id, password) {
    const payload = {
      setPdfPassword: {
        orderID,
        id,
        password,
      },
      ...auth,
    }

    const response = await axios.post(ep('order'), sanitize(payload))
    return response.data
  }

  static async TranslateAgain(auth, orderID) {
    const payload = {
      redo: {
        orderID,
      },
      ...auth,
    }

    const response = await axios.post(ep('translate'), sanitize(payload))
    return response.data
  }

  static async ReorderFiles(auth, orderID, files) {
    const payload = {
      orderID,
      reorderFiles: files,
      ...auth,
    }

    const response = await axios.post(ep('order'), sanitize(payload))
    return response.data
  }

  static async ResetOrderStatus(auth, orderID, status) {
    const payload = {
      resetStatus: {
        orderID,
        status,
      },
      ...auth,
    }

    const response = await axios.post(ep('order'), sanitize(payload))
    return response.data
  }

  static async OrderStatus(auth, orderID, listFiles) {
    const payload = {
      status: {
        orderID,
        listFiles,
      },
      ...auth,
    }

    const response = await axios.post(ep('order'), sanitize(payload), {
      params: { action: 'order-status' },
    })
    return response.data
  }

  static async CancelOrder(auth, orderID) {
    const payload = {
      cancel: {
        orderID,
      },
      ...auth,
    }

    const response = await axios.post(ep('order'), sanitize(payload))
    return response.data
  }

  static async OrderDelivered(auth, orderID) {
    const payload = {
      delivered: { orderID },
      ...auth,
    }
    const response = await axios.post(ep('order'), payload)
    return response.data
  }

  static async GetResultURL(auth, orderID) {
    const payload = {
      downloadResult: { orderID },
      ...auth,
    }
    const response = await axios.post(ep('order'), payload)
    return response.data
  }

  static async Translate(auth, orderID, language_in, language_out, add_ons) {
    const payload = {
      orderID,
      language_in,
      language_out,
      add_ons,
      ...auth,
    }
    const response = await axios.post(ep('translate'), payload)
    return response.data
  }

  static async TTS(auth, options) {
    const payload = {
      ...options,
      ...auth,
    }
    const response = await axios.post(ep('tts'), payload)
    return response.data
  }

  static async TTSAgain(auth, orderID) {
    const payload = {
      redo: { orderID },
      ...auth,
    }
    const response = await axios.post(ep('tts'), sanitize(payload))
    return response.data
  }

  static async EstimateTTSOrderCost(auth, orderID, voiceName) {
    const payload = {
      estimate: { orderID, voiceName },
      ...auth,
    }
    const response = await axios.post(ep('tts'), sanitize(payload))
    return response.data
  }

  static async GetSupportedVoices(auth, languageCode) {
    const response = await axios.post(ep('tts'), {
      supported: { languageCode },
    })
    return response.data
  }

  static GetVoicePreviewURL(auth, name, languageCode) {
    return ep(`tts/preview?voice=${name}&languageCode=${languageCode}`)
  }

  static async CreateOrder(auth, type) {
    const payload = {
      type,
      ...auth,
    }
    const response = await axios.post(ep('order'), sanitize(payload))
    return response.data
  }

  static async ListUserOrders(auth, { page, itemsPerPage }) {
    const payload = {
      user_orders: {
        page,
        itemsPerPage,
        ...auth,
      },
      ...auth,
    }

    const response = await axios.post(ep('user'), sanitize(payload))
    return response.data
  }

  static async ListUserTransactions(auth, { page, itemsPerPage }) {
    const payload = {
      user_transactions: {
        page,
        itemsPerPage,
        ...auth,
      },
      ...auth,
    }

    const response = await axios.post(ep('user'), sanitize(payload))
    return response.data
  }

  static async GetShopOffers(auth, selector) {
    // Construct the payload
    const payload = {
      ...auth,
      ...selector,
    }

    // Make the POST request using Axios
    const response = await axios.post(ep('store'), {
      shop_offers: sanitize(payload),
    })

    // Return the JSON response
    return response.data.products
  }

  static async GetSubscriptions(auth, selector, currency) {
    const offers = await PTRestAPI.GetShopOffers(auth, selector)
    const subs = _sortBy(
      offers.filter((o) => o.subscriptions?.length > 0),
      'subscriptions.0.duration',
    )
    return await getPrices(subs, currency, auth)
  }

  static async GetCreditPacks(auth, selector, currency) {
    const offers = await PTRestAPI.GetShopOffers(auth, selector)
    const packs = _sortBy(
      offers.filter((o) => o.subscriptions?.length == 0),
      'amount',
    )
    return await getPrices(packs, currency, auth)
  }

  static async RunDiscountQuery(auth, query) {
    const payload = {
      discount: query,
      ...auth,
    }
    const response = await axios.post(ep('store'), sanitize(payload))
    return response.data
  }

  static async PublishArticle(auth, articlePayload) {
    // Create a FormData object
    const formData = new FormData()

    // Append the articlePayload fields to FormData
    for (const key in articlePayload) {
      formData.append(key, articlePayload[key])
    }

    // Append the auth fields to FormData
    for (const key in auth) {
      formData.append(key, auth[key])
    }

    //add the command field
    formData.append('update', 1)

    // Make the POST request with multipart/form-data content type
    const response = await axios.post(ep('blog'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }

  //List articles
  static async ListArticles(auth, selector) {
    const payload = {
      list: {
        ...selector,
      },
    }
    const response = await axios.post(ep('blog'), sanitize(payload))
    return response.data
  }

  //Get article content
  static async GetArticle(articleID) {
    const response = await axios.post(ep('blog'), {
      get: {
        articleID,
      },
    })
    return response.data
  }
  static async UploadArticleResource(auth, formData, setProgress) {
    // Append the auth fields to FormData
    for (const key in auth) {
      formData.append(key, auth[key])
    }

    // Add the command field
    formData.append('upload', 1)

    // Make the POST request with multipart/form-data content type
    const response = await axios.post(ep('blog'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        // Calculate the progress as a percentage
        const progress = progressEvent.loaded / progressEvent.total
        // Update the progress state
        setProgress(progress)
      },
    })

    return response.data
  }

  static async ListArticleResources(auth, selector) {
    const payload = {
      resources: {
        ...selector,
        ...auth,
      },
    }
    const response = await axios.post(ep('blog'), sanitize(payload))
    return response.data
  }

  static async UpdateBlogCategory(auth, articlePayload) {
    // Create a FormData object
    const formData = new FormData()

    // Append the articlePayload fields to FormData
    for (const key in articlePayload) {
      formData.append(key, articlePayload[key])
    }

    // Append the auth fields to FormData
    for (const key in auth) {
      formData.append(key, auth[key])
    }

    //add the command field
    formData.append('category_update', 1)

    // Make the POST request with multipart/form-data content type
    const response = await axios.post(ep('blog'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
  //Get article content
  static async GetBlogCategory(categoryID) {
    const response = await axios.post(ep('blog'), {
      category_get: {
        categoryID,
      },
    })
    return response.data
  }

  //List Categories
  static async ListCategories(auth, selector) {
    const payload = {
      category_list: {
        ...selector,
      },
    }
    const response = await axios.post(ep('blog'), sanitize(payload))
    return response.data
  }

  //Blog article exists
  static async ArticleExists(auth, articleID) {
    const payload = {
      exists: {
        articleID,
        ...auth,
      },
    }

    const response = await axios.post(ep('blog'), sanitize(payload))
    return response.data
  }
}
